import {
    DetailsHeader,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsHeaderProps,
    IDetailsListProps, IDetailsListStyles,
    IDetailsRowStyles,
    mergeStyles,
    SearchBox,
    Selection, SelectionMode,
    Stack,
    Text,
    TooltipHost
} from "@fluentui/react";
import _ from "lodash";
import {Box} from "@dladio/core-ui";
import TableCell from "@dladio/core-ui/dist/components/DefaultTable/components/TableCell/TableCell";
import NoDataFound from "@dladio/core-ui/dist/components/DefaultTable/components/NoDataFound/NoDataFound";
import {multiSearch} from "@dladio/utils";
import {useOffsetTop} from "@dladio/hooks";
import {useLocalStore} from "@dladio/hooks";
import TableCommandBar from "./table-command-bar";
import React, {useEffect, useRef, useState} from "react";

interface TableInterface {
    config: any,
    model: any
}

const Table = ({config, model}: TableInterface) => {

    const [columns, setColumns] = useState([]) // need to set data
    const [lines, setLines] = useState(model)
    const [ref, offsetTop] = useOffsetTop()
    const [groups, setGroups] = useState()
    const {setStore} = useLocalStore('table-selection')

    let _selection = new Selection({
        onSelectionChanged: _onItemsSelectionChanged
    });

    const filteredList = useRef([])

    useEffect(() => {
        setLines(model)
        filteredList.current = model
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [config, model])

    function _onItemsSelectionChanged() {
        setStore("model", _selection.getSelection());
    };

    const generateGroup = (list: any) => {

        let uniqueArray = list.reduce((a: any, d: any) => {
            if (a.indexOf(_.get(d, config.group_by)) === -1) {
                a.push(_.get(d, config.group_by));
            }
            return a;
        }, []);

        let indexes = uniqueArray.map((_line: any) => ({
            key: _line,
            name: _line,
            count: list.filter((line: any) => _.get(line, config.group_by) == _line).length,
            level: 0,
            isCollapsed: true
        }))

        let groupedList: any = indexes.reduce((a: any, d: any) => {
            let startIndex = 0
            a.length > 0 && (startIndex = a[a.length - 1].startIndex + a[a.length - 1].count)
            a.push({...d, startIndex: startIndex});
            return a
        }, []);

        setGroups(groupedList)
    }

    // const modelModifier = (model: any, config: any) => {
    //     const newModel: any = []
    //
    //     model.forEach((line: any) => {
    //         let newLine = _.cloneDeep(line)
    //         for (let i in config) {
    //             if (config[i]?.data_type == "date") {
    //                 newLine[config[i].key] = new Date(newLine[config[i].key])
    //             }
    //         }
    //         newModel.push(newLine)
    //
    //     });
    //     return newModel
    // }

    const generateColumns = () => {
        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            // const newModel = modelModifier(filteredList.current, config["header_fields"])

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        };

        let columns = config && config.header_fields && config.header_fields.map((line: any) => ({
                key: line.name,
                name: line.name,
                fieldName: line.name,
                page: line.page,
                dataType: line.data_type,
                meta: line?.field_meta,
                sub_type: line.sub_type,
                isResizable: true,
                is_editable: line.is_editable,
                edit_data_type: line.edit_data_type,
                edit_query: line.edit_query,
                minWidth: line.min_width,
                maxWidth: line.max_width,
                isSortedDescending: false,
                isSorted: false,
                classList: line.class_list,
                date_format: line.date_format,
                onColumnClick: _onColumnClick
            })
        )

        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        return <TableCell model={model} index={index} column={column} path={config.path}/>
    }

    const stickyHeader: any = (tooltipHostProps: any) => {
        switch (tooltipHostProps?.column?.dataType) {
            case 'sticky':
                return (
                    <div ref={(e: any) => {
                        if (tooltipHostProps?.column?.dataType == 'sticky-header') e?.parentElement.classList.add('sticky-cell')
                    }}>
                        {tooltipHostProps.children}
                    </div>
                )
            default:
                return <TooltipHost>{tooltipHostProps.children}</TooltipHost>
        }
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                    // onRenderColumnHeaderTooltip={stickyHeader}
                />
            )
        }
        return null
    }

    const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        return props ? <DetailsRow {...props} styles={customStyles}/> : null;
    };

    let className = "overflow-auto"
    let height: any = "auto"

    if (config.height == 'autoFit') {
        height = `calc(100vh - ${offsetTop}px)`
    }

    if (config.height == "sm") {
        height = 250
    }

    if (config.height == "md") {
        height = 400
    }

    if (config.height == "lg") {
        height = 600
    }

    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? (multiSearch(model, text)) : model
        setLines(filteredLines)
        filteredList.current = filteredLines
    }

    return (
        <Box>
            <Stack className={'bg-white rounded-2'}>
                <div className="d-flex justify-content-between align-items-center">
                    <Text
                        className="ms-2 mt-1 capitalize text-lg">{config.title?.toLowerCase()} ({lines?.length ?? 0})</Text>
                </div>
                <div className={'flex flex-row'}>
                    {config.is_search_visible ?
                        <>
                            <div className={"w-1/4"}>
                                <SearchBox
                                    onChange={searchTextChangeHandler}
                                    className="w-25 m-2 rounded-2"
                                    placeholder="Search"
                                />
                            </div>
                        </>
                        : null}
                    <TableCommandBar
                        columns={columns}
                        actions={config?.actions}
                    />
                </div>

                <div ref={ref} className={className}>
                    <DetailsList
                        className={mergeStyles(_.set(gridStyles, ['root', 'selectors', '& [role=grid]', 'height'], height))}
                        items={lines}
                        columns={columns}
                        groups={groups}
                        selection={_selection}
                        compact={true}
                        setKey="none"
                        styles={gridStyles}
                        onRenderItemColumn={onRenderItemColumn}
                        onRenderRow={_onRenderRow}
                        onRenderDetailsHeader={_onRenderDetailHeader}
                        isHeaderVisible={true}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.multiple}
                    />
                    <NoDataFound visible={(lines?.length == 0)}/>
                </div>
            </Stack>
        </Box>
    )
}

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default Table