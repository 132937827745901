import _ from "lodash";
import React from "react";
import {Box} from "@dladio/core-ui";
import moment from "moment";
import {useForm} from "@dladio/hooks";
import {DatePicker, Stack} from "@fluentui/react";

const FilterStripe = ({data}: any) => {

    const {form, setForm, clearForm} = useForm("report-builder")

    function fromHandler(value: any) {
        setForm('from', moment(value).format('YYYY-MM-DD'))
    }

    function toHandler(value: any) {
        setForm('to', moment(value).format('YYYY-MM-DD'))
    }

    function clearFilter() {
        setForm('from', new Date())
    }

    return (
        <Box>
            <Stack horizontal className={'rounded-2 gap-2'}>
                <DatePicker
                    label={'From'}
                    allowTextInput
                    value={_.get(form, 'from') ? new Date(_.get(form, 'from')) : undefined}
                    onSelectDate={fromHandler as (date: Date | null | undefined) => void}
                />
                <DatePicker
                    label={'To'}
                    allowTextInput
                    ariaLabel="Select a date"
                    value={_.get(form, 'to') ? new Date(_.get(form, 'to')) : undefined}
                    onSelectDate={toHandler as (date: Date | null | undefined) => void}
                />
            </Stack>
        </Box>
    )
}

export default FilterStripe