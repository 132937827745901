import Table from "./Table/table";

const ReportTable = ({data, header_fields}: any) => {

    const config = {
        is_search_visible: true,
        height: 'lg',
        title: "",
        selection_mode: 2,
        header_fields: header_fields,
        actions: [{
            "command": "to-excel",
            "text": "",
            "icon": "ExcelDocument",
        }]
    }

    return (
        <>
            <Table config={config} model={data ?? {}}/>
        </>

    )
}

export default ReportTable

