import {useRoutes} from "react-router-dom";
import Home from "../components/pages/Home/Home";
import Template1 from "../examples/Template1/Template1";


const RouterConfigs = () => {


    const element = useRoutes(
        [
            {
                path: "/",
                element: <Home/>,
            }
            // ,
            // {
            //     path: "/temp",
            //     element: <Template1/>,
            // }
        ]);

    return element;
};

export default RouterConfigs;