import {DefaultCommandBar, TitleBar} from "@dladio/core-ui";
import {dispatchAction} from "@dladio/app-bridge";
import FilterStripe from "./filter-stripe/filter-stripe";

const Header = () => {

    return (
        <>
            <TitleBar title={"Report Viewer"} subTitle={"v1.0.1"}/>
            <DefaultCommandBar actions={[
                {
                    key: 1,
                    text: 'Back',
                    iconProps: {iconName: 'Back'},
                    onClick: () => {
                        dispatchAction({
                            action: 'navigate',
                            payload: -1
                        }, "https://helios.dlad.io/")
                    },
                }
            ]}/>
            <FilterStripe/>
        </>
    )
}

export default Header