import {erpMethodApi} from "@dladio/service";

export const fetchCurrentUserAction = () => {
    return async (dispatch: any, getState: any) => {
        const {error, data} = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: 'dlad_core.api.get_current_user',
            body: {}
        }), {subscribe: false, forceRefetch: true})

        if (!error && data) {
            return data?.message
        }
    }
}
