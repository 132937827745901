import {isArray} from "@dladio/utils";
import {useLocalStore} from "@dladio/hooks";
import {CommandBar, IButtonProps} from "@fluentui/react";

const overflowProps: IButtonProps = {ariaLabel: "More commands"};

const TableCommandBar = ({actions, columns}: any) => {

    const {model} = useLocalStore('table-selection')

    const headerExtractor = (obj: any) => {
        let x = []

        for (const [key, value] of Object.entries(obj)) {
            x.push(JSON.stringify((key)))
        }
        return x.join(",")
    }

    const actionList = [

        {
            command: "to-excel",
            action: () => {
                const lines: any = model?.model
                const headers = headerExtractor(lines[0])
                const replacer = (key: any, value: any) =>
                    value === null ? "" : value; // specify how you want to handle null values here
                let csv: any = [
                    headers,
                    ...lines.map((row: any) => {
                            let x = []

                            for (const [key, value] of Object.entries(row)) {
                                x.push(JSON.stringify(value, replacer))
                            }
                            return x.join(",")
                        }
                    )].join("\r\n")

                const file = new Blob([csv], {type: "csv"});
                const a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = "file.csv";
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            },
        },
    ]

    const items: any = isArray(actions) && actions.map((item: any, index: number) => {
        let parsedItem = JSON.parse(JSON.stringify(item))
        let filteredAction = actionList.find(action => action.command === parsedItem.command)
        let {action}: any = filteredAction ? filteredAction : {}

        delete parsedItem.type

        return {
            key: index,
            text: parsedItem.text,
            iconProps: {iconName: parsedItem.icon},
            onClick: action?.bind(null, parsedItem)
        }
    })

    return (
        <div className={'mx-2 mb-2'}>
            <CommandBar
                className={'bg-surface'}
                items={items}
                overflowButtonProps={overflowProps}
                ariaLabel="Inbox actions"
                primaryGroupAriaLabel="Email actions"
                farItemsGroupAriaLabel="More actions"
            />
        </div>
    )
}

export default TableCommandBar