import _ from "lodash";
import {Box} from "@dladio/core-ui";
import Header from "./components/header";
import {Spinner} from "@fluentui/react";
import {useForm} from "@dladio/hooks";
import ReportTable from "./components/report-table";
import {useDispatch} from "react-redux";
import {EXEC_QUERY_METHOD} from "@dladio/service/dist/const/erp-method.consts";
import {useAppBridgeSelector} from "@dladio/app-bridge";
import {useExecMethodApiQuery} from "@dladio/service/dist/services/erp-method-api.service";
import {fetchCurrentUserAction} from "../../../actions/actions";
import React, {useEffect, useState} from "react";

const Home = () => {
    const dispatch: any = useDispatch()
    const {model} = useAppBridgeSelector()
    const {form, setForm, clearForm} = useForm("report-builder")
    const [isLoading, setIsLoading] = useState(true)
    const [userData, setUserData] = useState<any>({})
    const params = _.get(model, 'params')
    let header: any = []

    const getUserData = async () => {
        return await dispatch(fetchCurrentUserAction())

    }

    let {data} = useExecMethodApiQuery({
        method: EXEC_QUERY_METHOD,
        body: {
            name: _.get(model, 'params.id'),
            ...form,
            ...userData,
            params: params
        }
    })

    useEffect(() => {
        getUserData().then(data => {
            setUserData(data)
        })

    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false)
        }
    }, [data])


    if (data) {
        const headerExtractor = (obj: any) => {
            let x = []

            for (const [key, value] of Object.entries(obj)) {
                x.push(({name: key}))
            }
            return x
        }
        header = headerExtractor(data?.message[0])
    }

    return (
        <>
            <Header/>
            {
                isLoading ?
                    <Box>
                        <div className={'min-h-[680px] flex items-center'}>
                            <div className={'m-auto'}>
                                <Spinner/>
                                <p className={'text-blue-600'}>Processing...</p>
                            </div>

                        </div>
                    </Box> :
                    <>
                        <ReportTable data={data?.message} header_fields={header}/>
                    </>
            }
        </>
    )
}

export default Home